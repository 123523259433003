<template>
  <div>
    <b-card class="newproject-card projectListinfo">
      <!-- <div
        class="
          d-flex
          justify-content-between
          align-items-center
          newproject-timeline-section
        "
      >
        <short-text style="border-radius: 3px"></short-text> -->

        <!-- <b-badge pill class="newproject-card-status fixcost">Fix Cost</b-badge>
        <b-badge pill class="newproject-card-status fixtimeline"
          >Fix Timeline</b-badge
        >
        <b-badge pill class="newproject-card-status hourly">Hourly</b-badge> -->

        <!-- <short-text></short-text> -->
        <!-- <b-badge class="newproject-card-status p-inplanning"
          >In Planning</b-badge
        >
        <b-badge class="newproject-card-status p-insupport">In Support</b-badge>
        <b-badge class="newproject-card-status p-completed">Completed</b-badge> -->
      <!-- </div> -->
      <div class="newproject-title-section" style="background-color:#fff;">
        <p class="newproject-title">
          <text-shimmer></text-shimmer>
        </p>
        <!-- <p class="newproject-des">
          <long-text-shimmer class="my-1"></long-text-shimmer>
          <long-text-shimmer class="mb-1"></long-text-shimmer>
        </p> -->
      </div>
      <div class="projectListBody">
        <div class="mb-2">
          <b-row class="">
            <b-col cols="6" class="pr-0">
              <p class="newproject-scop-member"><text-shimmer class="cardShimmer"></text-shimmer></p>                          
            </b-col>
            <b-col cols="6" class="text-right">
              <p class="newproject-scop-member"><text-shimmer  class="cardShimmer"></text-shimmer></p>              
            </b-col> 
          </b-row>
      </div>
      <div class="mb-2">
        <b-row class="newproject-date-section">
          <b-col cols="6" class="pr-0">
            <p class="newproject-scop-member"><text-shimmer class="cardShimmer"></text-shimmer></p>
            <p class="newproject-timeline-date">
              <text-shimmer class="mt-25 cardShimmer"></text-shimmer>
            </p>
          </b-col>
          <b-col cols="6">
            <p class="newproject-scop-member"><text-shimmer class="cardShimmer"></text-shimmer></p>
            <p class="newproject-timeline-date">
              <text-shimmer class="mt-25 cardShimmer"></text-shimmer>
            </p>
          </b-col>
        </b-row>
      </div>
      <div class="mb-2">
        <b-row class="newproject-date-section">
          <b-col cols="6" class="pr-0">
            <p class="newproject-scop-member"><text-shimmer class="cardShimmer"></text-shimmer></p>
            <p class="newproject-timeline-date">
              <text-shimmer class="mt-25 cardShimmer"></text-shimmer>
            </p>
          </b-col>
          <b-col cols="6">
            <p class="newproject-scop-member"><text-shimmer class="cardShimmer"></text-shimmer></p>
            <p class="newproject-timeline-date">
              <text-shimmer class="mt-25 cardShimmer"></text-shimmer>
            </p>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="newproject-date-section align-items-center">
          <b-col cols="6" class="pr-0">              
            <div class="d-flex justify-content-start align-items-center">
              <circle-shimmer-vue></circle-shimmer-vue>
              <circle-shimmer-vue></circle-shimmer-vue>
              <circle-shimmer-vue></circle-shimmer-vue>                               
            </div>
            </b-col> 
          <b-col cols="6" class="text-right">
            <p class="newproject-scop-member"><text-shimmer class="cardShimmer"></text-shimmer></p>              
          </b-col>
        </b-row>
      </div>          
      <!-- <div>
        <b-row>
          <b-col cols="12">
            <div class="">
              <long-text-shimmer class="mt-1"></long-text-shimmer>
              <long-text-shimmer></long-text-shimmer>
            </div>
          </b-col>
        </b-row>
      </div> -->
    </div>
    <div class="projectFooter">
        <div class="newproject-date-section m-0 d-flex justify-content-between" style="flex: 1">                    
          <div class="total-spent projectSpent">
            <short-text></short-text>
            <short-text></short-text>
            <short-text></short-text>
          </div>
          <div class="total-spent projectSpent">
            <short-text></short-text>
            <short-text></short-text>
            <short-text></short-text>
          </div>                     
          <div>
            <short-text></short-text>
            <short-text></short-text>
            <short-text></short-text>
          </div>
        </div>
      </div>  
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  VBTooltip,
} from "bootstrap-vue"
import TextShimmer from "../../views/shimmer/TextShimmer.vue"
import ShortText from "../../views/shimmer/shortText.vue"
import CircleShimmerVue from "../../views/shimmer/CircleShimmer.vue"

export default {
  name: "NewProjectCard",
  components: {
    BCard,
    BRow,
    BCol,
    TextShimmer,
    ShortText,
    CircleShimmerVue,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
}
</script>

<style>
@import url("../../assets/scss/component-css/projectcards.css");

.cardShimmer .loader {width: 100%;min-width: 100%;max-width: 100%;}
</style>